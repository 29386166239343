import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const RequestsServiceLogo: FC<SvgIconProps> = ({ sx, ...rest }) => (
  <SvgIcon
    viewBox="0 0 76 76"
    sx={{ width: "76px", height: "76px", ...sx }}
    {...rest}
  >
    <path
      d="M8.25449 2.33433H56.4831C56.1011 0.990321 54.8703 0 53.3989 0H6.66999C4.90156 0 3.47266 1.42889 3.47266
      3.19732V63.2532C3.47266 64.965 4.76008 66.2383 6.27385 66.4363V4.31497C6.27385 3.22562 7.16514 2.33433 8.25449 2.33433Z"
      fill="#E6EFF6"
    />
    <path
      d="M17.4779 61.3432C17.8457 61.2018 18.2419 61.1169 18.6521 61.1169H53.4124C55.1808 61.1169 56.6097 59.688
      56.6097 57.9195V48.8228L52.1957 53.7461L45.122 47.408L56.6239 34.5763V4.71106H8.66406V65.8563L17.2374 61.4564C17.3081
      61.414 17.393 61.3857 17.4779 61.3432ZM49.9604 56.2502L48.2203 58.1459C48.0647 58.3015 47.8241 58.4572 47.5836
      58.542L41.5568 60.2822C40.609 60.5227 39.6611 59.7304 39.8167 58.6977L40.8495 52.5152C40.8495 52.2747 41.0051
      52.0342 41.1607 51.8079L42.9008 49.9121L49.9604 56.2502ZM34.5397 15.2367H50.5263C51.1771 15.2367 51.7147 15.7744
      51.7147 16.4251C51.7147 17.0759 51.1771 17.6135 50.5263 17.6135H34.5397C33.8889 17.6135 33.3513 17.0759 33.3513
      16.4251C33.3513 15.7744 33.8748 15.2367 34.5397 15.2367ZM34.5397 22.0275H50.5263C51.1771 22.0275 51.7147 22.5651
      51.7147 23.2159C51.7147 23.8667 51.1771 24.4043 50.5263 24.4043H34.5397C33.8889 24.4043 33.3513 23.8667 33.3513
      23.2159C33.3513 22.551 33.8748 22.0275 34.5397 22.0275ZM34.5397 28.8183H50.5263C51.1771 28.8183 51.7147 29.3559
      51.7147 30.0067C51.7147 30.6574 51.1771 31.1951 50.5263 31.1951H34.5397C33.8889 31.1951 33.3513 30.6574 33.3513
      30.0067C33.3513 29.3559 33.8748 28.8183 34.5397 28.8183ZM23.434 15.4207V14.3172C23.434 13.5391 24.0706 13.1712
      24.6789 13.1712C25.2873 13.1712 25.9098 13.5532 25.9098 14.3172V15.4348C28.0743 15.9441 29.3759 17.3589 29.9984
      19.1556C30.4228 20.6552 28.1592 21.2636 27.6358 19.8488C27.1406 18.3916 26.1503 17.656 24.6365 17.5994C22.953
      17.6135 21.6514 18.7878 21.6514 20.2732C21.6514 20.9806 21.9202 21.207 22.288 21.3626C22.7832 21.5889 23.7452
      21.7304 24.8063 21.8295C25.8673 21.9285 27.0557 21.9992 28.1592 22.5085C29.2627 23.0037 30.1823 23.994 30.1823
      25.6634C30.1823 28.0402 28.329 30.0208 25.9098 30.5301V31.5346C25.9098 33.0342 23.434 33.0908 23.434
      31.5346V30.5301C21.8212 30.1481 20.1518 29.0871 19.3595 26.7952C18.9068 25.3239 21.1845 24.6589 21.7221
      26.102C22.189 27.545 23.3067 28.3514 24.7214 28.3797C26.4049 28.3656 27.7065 27.1347 27.7065 25.6776C27.7065
      24.8994 27.367 24.7438 27.0699 24.5882C26.5747 24.3618 25.6127 24.2345 24.5516 24.1213C23.4906 24.0223
      22.3022 23.9516 21.1987 23.4423C20.0952 22.9471 19.1756 21.9709 19.1756 20.2732C19.1614 17.8823 21.0148
      15.93 23.434 15.4207Z"
      fill="#E6EFF6"
    />
    <path
      d="M61.7875 47.6344V65.828H23.8299C23.3772 65.828 22.9386 65.927 22.5425 66.0968L8.66388
      73.1988V65.8422V4.71111H56.6237H61.8016V24.2204L64.1784 21.5607V4.30083C64.1784 3.21148
      63.2871 2.32019 62.1978 2.32019H56.4963H8.26775C7.1784 2.32019 6.28711 3.21148 6.28711
      4.30083V66.4363V74.0194C6.28711 75.1087 7.1784 76 8.26775 76L22.9669 68.4736C23.3772 68.2897 23.8158 68.1906
      24.2685 68.1906H62.1978C63.2871 68.1906 64.1784 67.2993 64.1784 66.21V44.9605L61.7875 47.6344Z"
      fill="#B0CDE3"
    />
    <path
      d="M34.54 31.1951H50.5265C51.1773 31.1951 51.7149 30.6575 51.7149 30.0067C51.7149 29.356 51.1773 28.8184
      50.5265 28.8184H34.54C33.8892 28.8184 33.3516 29.356 33.3516 30.0067C33.3516 30.6575 33.875 31.1951 34.54 31.1951Z"
      fill="#005EA6"
    />
    <path
      d="M21.1993 23.4565C22.3028 23.9516 23.4912 24.0365 24.5523 24.1355C25.6133 24.2346 26.5754 24.376 27.0705
      24.6024C27.3818 24.758 27.7072 24.9136 27.7072 25.6918C27.7072 27.1631 26.4056 28.3798 24.7221 28.3939C23.3073
      28.3656 22.1897 27.5592 21.7228 26.1162C21.1852 24.6731 18.9075 25.3381 19.3602 26.8094C20.1524 29.1013 21.8077
      30.1482 23.4346 30.5443V31.5488C23.4346 33.105 25.9104 33.0484 25.9104 31.5488V30.5443C28.3438 30.035 30.183
      28.0544 30.183 25.6776C30.183 24.0082 29.2634 23.032 28.1599 22.5227C27.0564 22.0276 25.868 21.9427 24.8069
      21.8437C23.7459 21.7446 22.7838 21.6031 22.2887 21.3768C21.9209 21.207 21.6521 20.9948 21.6521 20.2874C21.6521
      18.7878 22.9536 17.6277 24.6372 17.6136C26.151 17.6702 27.1413 18.4058 27.6364 19.863C28.1599 21.2778 30.4235
      20.6836 29.999 19.1698C29.3907 17.3731 28.0891 15.9583 25.9104 15.449V14.3314C25.9104 13.5674 25.2879 13.1854
      24.6796 13.1854C24.0713 13.1854 23.4346 13.5533 23.4346 14.3314V15.4349C21.0154 15.9442 19.1621 17.8965 19.1621
      20.3016C19.1621 21.9851 20.0958 22.9472 21.1993 23.4565Z"
      fill="#54C5B5"
    />
    <path
      d="M34.54 17.6136H50.5265C51.1773 17.6136 51.7149 17.076 51.7149 16.4252C51.7149 15.7744 51.1773 15.2368
      50.5265 15.2368H34.54C33.8892 15.2368 33.3516 15.7744 33.3516 16.4252C33.3516 17.076 33.875 17.6136 34.54 17.6136Z"
      fill="#005EA6"
    />
    <path
      d="M34.54 24.4044H50.5265C51.1773 24.4044 51.7149 23.8668 51.7149 23.216C51.7149 22.5652 51.1773 22.0276 50.5265
      22.0276H34.54C33.8892 22.0276 33.3516 22.5652 33.3516 23.216C33.3516 23.8668 33.875 24.4044 34.54 24.4044Z"
      fill="#005EA6"
    />
    <path
      d="M45.1094 47.4222L52.1972 53.7603L56.6112 48.837L60.1481 44.8898L67.052 37.1795L59.9783 30.8414L59.2568
      31.6337L56.6112 34.5905L45.1094 47.4222Z"
      fill="#005EA6"
    />
    <path
      d="M65.1688 25.1116L62.2402 28.4363L69.2857 34.7743L72.2142 31.4497C72.2566 31.3931 72.2991 31.3365 72.3415
      31.2658C72.6669 30.6999 72.5537 29.8935 72.0586 29.469L69.7384 27.3752L67.1494 25.0409C66.5977 24.4892 65.6498
      24.5599 65.1688 25.1116Z"
      fill="#005EA6"
    />
    <path
      d="M41.1753 51.822C41.0197 52.0625 40.8641 52.303 40.8641 52.5294L39.8313 58.7118C39.6757 59.7446 40.6236 60.5368
      41.5715 60.2963L47.5983 58.5562C47.8388 58.4713 48.0793 58.3157 48.2349 58.1601L49.975 56.2643L42.9296
      49.9263L41.1753 51.822Z"
      fill="#005EA6"
    />
  </SvgIcon>
);
