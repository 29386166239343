import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const AgreementsServiceLogo: FC<SvgIconProps> = ({ sx, ...rest }) => (
  <SvgIcon
    viewBox="0 0 76 76"
    sx={{ width: "76px", height: "76px", ...sx }}
    {...rest}
  >
    <path
      d="M34.2722 0C41.9778 0 48.2632 6.29727 48.2632 14.0513C48.2632 21.8053 41.9929 28.1025 34.2722 28.1025C26.5515 28.1025 20.2812
      21.8053 20.2812 14.0513C20.2812 6.29727 26.5515 0 34.2722 0Z"
      fill="#E6EFF6"
    />
    <path
      d="M74.5154 50.7123C71.2813 45.0918 68.0332 39.4713 64.8131 33.8366C63.8991 32.2469 61.9165 32.0198 60.5526 32.8147L55.2655
      35.8804C53.7891 36.732 53.1563 38.6055 54.0141 40.0958L54.1547 40.3371C51.7502 41.2028 49.2614 41.0041 47.4334 40.6919C44.4524
      40.1667 41.0214 40.0532 37.942 40.3938C35.6219 40.6493 31.9238 41.3873 30.2786 43.4737L22.8824 41.9977L22.9949 41.8131C23.8667
      40.2945 23.3745 38.3358 21.8278 37.4416L16.5548 34.3901C15.0221 33.4959 13.0676 34.0353 12.1958 35.5539L1.52331
      54.0903C0.637453 55.6373 1.18584 57.5818 2.6904 58.4618L7.96338 61.5133C9.482 62.3933 11.324 61.8256 12.1255 60.6333L15.1065
      62.8758C14.1503 64.65 14.7972 66.8499 16.5126 67.8435C17.0188 68.1415 17.5391 68.2976 18.1016 68.326C17.8485 69.8163
      18.5375 71.3634 19.9014 72.1582C20.9982 72.7969 22.2918 72.7969 23.3605 72.2859C23.7261 72.9956 24.162 73.4498 24.8088
      73.833C25.9056 74.4717 27.1992 74.4717 28.2679 73.9607C28.5772 74.5852 29.0694 75.1388 29.7162 75.5078C31.4738 76.5297
      33.7518 75.9052 34.7642 74.1452L35.3266 73.1801C36.5921 73.9181 37.8577 74.6562 39.1232 75.3942C40.8949 76.4303 43.1447
      75.8058 44.1571 74.0317C44.4102 73.5917 44.5508 73.1517 44.6211 72.6691C46.3507 73.4498 48.3755 72.7969 49.3317
      71.1505C49.6269 70.6395 49.7816 70.1144 49.8238 69.5608C51.3002 69.8163 52.8329 69.1492 53.6203 67.7725C53.8735 67.3325
      54.0422 66.8641 54.0984 66.3958C55.828 67.1906 57.8387 66.5235 58.809 64.8771C59.5964 63.5287 59.2449 62.0526 59.048
      61.6268L63.6882 56.9715C64.546 58.476 66.5005 58.8592 67.9628 58.0076L73.2499 54.9419C74.7404 54.0619 75.3732 52.1884
      74.5154 50.7123ZM10.3257 59.1856C10.0866 59.5972 9.56636 59.7533 9.13046 59.4979L3.85749 56.4463C3.46377 56.2192 3.28097
      55.6799 3.53408 55.2541C3.745 54.8993 10.6631 42.8918 14.2066 36.7036C14.4597 36.2636 14.994 36.1359 15.4018 36.3772L20.6748
      39.4429C21.0966 39.6842 21.2513 40.2093 20.9982 40.6493C17.4407 46.8233 10.5225 58.8308 10.3257 59.1856ZM20.0701
      64.4371L19.5639 65.3171C19.1702 65.9983 18.3265 66.2112 17.6797 65.828C17.0329 65.459 16.7798 64.6074 17.1735 63.9261L17.6797
      63.0462C18.0734 62.3649 18.9171 62.1662 19.578 62.5494C20.2248 62.9326 20.4639 63.7558 20.0701 64.4371ZM22.9527 69.646C22.559
      70.3273 21.7294 70.526 21.0685 70.157C20.4076 69.7737 20.1686 68.9505 20.5482 68.2693C21.6028 66.4241 22.5027 64.8629 23.5995
      62.9752C23.9932 62.2797 24.8369 62.0952 25.4978 62.4642C26.1446 62.8475 26.3977 63.6849 26.004 64.3661C24.9494 66.1545 23.8526
      68.0706 22.9527 69.646ZM29.8849 67.7725C29.21 68.9505 28.535 70.1286 27.846 71.3066C27.4523 71.9879 26.6086 72.1866 25.9618
      71.8176C25.315 71.4343 25.0619 70.5969 25.4556 69.9157L27.4804 66.3816C27.8741 65.7003 28.7178 65.4874 29.3787 65.8706C30.0536
      66.268 30.2786 67.1054 29.8849 67.7725ZM33.7799 71.2073L32.7675 72.9814C32.3738 73.6627 31.516 73.8614 30.8833
      73.4924C30.2364 73.1233 29.9833 72.2717 30.3771 71.5905L31.3895 69.8305C31.7832 69.1634 32.6269 68.9505 33.2877
      69.3337C33.9346 69.7028 34.1736 70.526 33.7799 71.2073ZM56.8263 63.6849C56.4467 64.3377 55.617 64.5932 54.928
      64.1958C50.4425 61.5843 43.4681 57.5676 41.4995 56.4179C40.1778 55.6373 39.0107 57.6669 40.3465 58.4334L51.1315
      64.6784C51.8205 65.0758 52.0033 65.9132 51.6377 66.5661C51.2581 67.219 50.4284 67.4744 49.7394 67.077C46.66 65.2887
      43.5806 63.5145 40.5012 61.7262C39.1654 60.9598 38.0123 62.9752 39.3482 63.7416C41.837 65.1893 44.3399 66.6228 46.8287
      68.0706C47.5037 68.468 47.7005 69.3054 47.3209 69.9583C46.9412 70.6111 46.1116 70.8666 45.4226 70.4692C43.3697 69.277
      41.3167 68.0848 39.2638 66.9067C37.928 66.1261 36.7749 68.1557 38.1108 68.9363C39.2778 69.6176 40.4731 70.2989 41.6401
      70.966C42.3291 71.3634 42.5119 72.215 42.1463 72.8537C41.7808 73.4924 40.923 73.762 40.2481 73.3646C38.9123 72.584 37.5764
      71.8176 36.2265 71.0369C36.4234 69.5892 35.7625 68.0989 34.4126 67.3183C33.7658 66.9493 33.0487 66.779 32.3597
      66.8216C32.2753 65.6293 31.6144 64.5223 30.5317 63.8836C29.8849 63.5145 29.1818 63.3442 28.4788 63.3868C28.3944
      62.1946 27.7335 61.0875 26.6368 60.4488C24.9916 59.4979 22.8965 59.9379 21.7856 61.4991C21.5044 61.1159 21.1388
      60.8036 20.717 60.5624C19.2968 59.7392 17.5672 59.9662 16.4283 60.9882L13.3207 58.6179L21.6731 44.1124L28.7741
      45.5318C28.1835 46.4401 27.607 47.4478 27.1008 48.4839C26.5805 49.5342 26.1446 50.5987 25.8774 51.5781C25.6384
      52.4722 25.4275 53.5225 25.7931 54.5019C26.2149 55.6373 27.2133 56.276 28.4366 56.2334C29.5053 56.205 30.588
      55.7367 31.5723 55.1547C33.4002 54.0903 35.3266 52.3445 36.8031 50.471L56.3201 61.783C57.0091 62.1804 57.206
      63.0178 56.8263 63.6849ZM57.5575 59.8243C57.5575 59.8243 43.8899 51.9045 37.0562 47.9588C36.5359 47.6607 35.8891
      47.8027 35.5375 48.2994C34.2017 50.2013 32.1207 52.1458 30.3911 53.1535C29.5334 53.6645 28.76 53.9199 28.3663
      53.9199C28.0991 53.9199 27.9585 53.9199 27.8882 53.5935C27.8601 53.4941 27.8601 52.9406 28.071 52.231C28.2819
      51.4503 28.6616 50.5136 29.1397 49.5484C30.0818 47.6324 31.516 45.4608 32.191 44.7795C33.2174 43.7434 35.3547
      43.0338 38.1811 42.7073C40.9933 42.3809 44.2696 42.4944 47.0115 42.977C50.0347 43.5163 52.3548 43.2467 53.9297
      42.8351C54.4359 42.6931 54.9984 42.5228 55.2936 42.3667C57.7262 46.5679 60.0604 50.6839 62.4649 54.8851C60.9322
      56.4463 58.7808 58.5895 57.5575 59.8243ZM72.1109 52.898C70.3533 53.9199 66.8239 55.9638 66.8239 55.9638C66.388
      56.2192 65.8818 56.0915 65.713 55.8076C65.5302 55.4812 59.287 44.524 56.0108 38.9319C55.842 38.6339 55.9686 38.1513
      56.4185 37.8958L61.7056 34.8301C62.1134 34.5888 62.6196 34.6456 62.8164 34.9862C63.0133 35.3127 69.2846 46.2414
      72.5187 51.8619C72.6874 52.16 72.5468 52.6567 72.1109 52.898Z"
      fill="#005EA6"
    />
    <path
      d="M35.0463 18.1389C35.9884 18.5647 36.9868 18.6357 37.9008 18.7208C38.8007 18.806 39.6303 18.9195 40.0381
      19.1183C40.2912 19.2602 40.5865 19.3879 40.5865 20.055C40.5865 21.3182 39.4756 22.3685 38.0414 22.3685C36.8321
      22.3401 35.89 21.6588 35.4963 20.424C35.0323 19.1892 33.1059 19.7569 33.4855 21.0201C34.1605 22.9788 35.5666
      23.8872 36.9446 24.2136V25.0794C36.9446 26.4136 39.0538 26.3568 39.0538 25.0794V24.2278C41.1208 23.7878 42.6816
      22.0988 42.6816 20.055C42.6816 18.6215 41.8942 17.7841 40.9521 17.3441C40.01 16.9183 39.0116 16.8473 38.0976
      16.7622C37.1977 16.677 36.3681 16.5493 35.9603 16.3648C35.651 16.2228 35.4119 16.0383 35.4119 15.428C35.4119 14.1506
      36.5228 13.1429 37.957 13.1287C39.2507 13.1713 40.0803 13.81 40.5021 15.059C40.9521 16.2796 42.8785 15.7545 42.5129
      14.4629C41.9926 12.9158 40.8818 11.7094 39.0397 11.2694V10.3043C39.0397 9.6514 38.5054 9.32495 37.9851 9.32495C37.4649
      9.32495 36.9305 9.6514 36.9305 10.3043V11.241C34.8635 11.681 33.3027 13.3558 33.3027 15.4138C33.3309 16.8757 34.1042
      17.7131 35.0463 18.1389Z"
      fill="#54C5B5"
    />
    <path
      d="M37.9971 4.57023C45.1684 4.57023 51.0179 10.4604 51.0179 17.7131C51.0179 24.9658 45.1684 30.856 37.9971
      30.856C30.8259 30.856 24.9764 24.9658 24.9764 17.7131C24.9764 10.4604 30.8118 4.57023 37.9971 4.57023ZM37.9971
      2.29932C29.5603 2.29932 22.7266 9.19721 22.7266 17.7131C22.7266 26.229 29.5603 33.1269 37.9971 33.1269C46.4339
      33.1269 53.2677 26.229 53.2677 17.7131C53.2677 9.19721 46.4198 2.29932 37.9971 2.29932Z"
      fill="#B0CDE3"
    />
  </SvgIcon>
);
