import { createNamedStyles } from "../../../shared/utils";

export const buttonStyles = createNamedStyles({
  button: {
    height: "auto",
    width: "auto",
    p: 2,
    color: "text.dark",
    borderRadius: "4px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "primary.light3",
    bgcolor: "background.paper",
    display: "flex",
    flexDirection: { xs: "row", md: "column" },
    justifyContent: { xs: "normal", md: "center" },
    alignItems: "center",
    boxShadow: "none",

    "&:focus": {
      bgcolor: "background.paper",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "primary.light3",
      boxShadow: "none",
    },

    "&:hover": {
      color: "text.dark",
      bgcolor: "background.paper",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "primary.light3",
      boxShadow: {
        xs: "-8px 8px 24px -4px rgba(145, 158, 171, 0.2)",
        md: "0px 4px 6px rgba(50, 50, 93, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.08);",
      },
    },

    "&:active": {
      color: "text.dark",
      bgcolor: "background.paper",
      borderStyle: "solid",
      borderWidth: "2px",
      borderColor: "primary.main",
      boxShadow: "none",
    },

    "&:disabled": {
      color: "text.dark",
      bgcolor: "primary.light3",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "primary.light3",
      opacity: 0.5,
      boxShadow: "none",
    },
  },

  selected: {
    color: "text.dark",
    bgcolor: "background.paper",
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: "primary.main",
    boxShadow: "none",

    "&:focus": {
      color: "text.dark",
      bgcolor: "background.paper",
      borderStyle: "solid",
      borderWidth: "2px",
      borderColor: "primary.main",
      boxShadow: "none",
    },

    "&:hover": {
      color: "text.dark",
      bgcolor: "background.paper",
      borderStyle: "solid",
      borderWidth: "2px",
      borderColor: "primary.main",
      boxShadow: {
        xs: "-8px 8px 24px -4px rgba(145, 158, 171, 0.2)",
        md: "0px 4px 6px rgba(50, 50, 93, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.08);",
      },
    },
  },
});
