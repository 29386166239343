import { Box, Grid, Typography } from "@mui/material";
import { FC, memo } from "react";
import {
  AgreementsServiceLogo,
  CitationsInfractionsServiceLogo,
  RequestsServiceLogo,
} from "../../../../assets/icons";
import ChoiceButton from "../../../common/ChoiceButton/ChoiceButton";
import { ServiceChoicesProps } from "./ServiceChoices.interfaces";
import { serviceChoicesStyles as styles } from "./ServiceChoices.styles";

const ServiceChoices: FC<ServiceChoicesProps> = ({
  onCitationsClick,
  onRequestsClick,
  onAgreementsClick,
  selectedScreenName,
  ...rest
}) => (
  <Grid container spacing={2} {...rest}>
    <Grid item xs={12} md={4}>
      <ChoiceButton
        sx={styles.button}
        onClick={onCitationsClick}
        selected={selectedScreenName === "citations"}
      >
        <CitationsInfractionsServiceLogo />
        <Typography sx={styles.text} variant="subtitle1">
          Citaciones / Infracciones
        </Typography>
      </ChoiceButton>
    </Grid>

    <Grid item xs={12} md={4}>
      <ChoiceButton
        sx={styles.button}
        onClick={onRequestsClick}
        selected={selectedScreenName === "requests"}
      >
        <RequestsServiceLogo />
        <Box>
          <Typography sx={styles.text} variant="subtitle1">
            Órdenes de pago /
          </Typography>
          <Typography component="span" sx={styles.text} variant="subtitle1">
            Solicitud de servicio
          </Typography>
        </Box>
      </ChoiceButton>
    </Grid>

    <Grid item xs={12} md={4}>
      <ChoiceButton
        sx={styles.button}
        onClick={onAgreementsClick}
        selected={selectedScreenName === "agreements"}
      >
        <AgreementsServiceLogo />
        <Typography sx={styles.text} variant="subtitle1">
          Convenios de pago
        </Typography>
      </ChoiceButton>
    </Grid>
  </Grid>
);

export default memo(ServiceChoices);
