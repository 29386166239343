import { createNamedStyles } from "../../../../shared/utils";

export const serviceChoicesStyles = createNamedStyles({
  button: {
    width: "100%",
    height: ({ spacing }) => ({ xs: spacing(13.5), md: spacing(17) }),
  },
  text: {
    fontWeight: 400,
    color: "text.dark",
    whiteSpace: "nowrap",
    mt: { xs: 0, md: 1 },
    ml: { xs: 2, md: 0 },
  },
});
