import { Button } from "@mui/material";
import { FC } from "react";
import { ChoiceButtonProps } from "./ChoiceButton.interfaces";
import { buttonStyles as styles } from "./ChoiceButton.styles";

const ChoiceButton: FC<ChoiceButtonProps> = ({
  selected = false,
  sx,
  ...rest
}) => {
  const selectedStyle = selected ? styles.selected : undefined;

  const buttonStyle = { ...styles.button, ...selectedStyle, ...sx };

  return <Button sx={buttonStyle} {...rest} />;
};

export default ChoiceButton;
