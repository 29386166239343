import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const CitationsInfractionsServiceLogo: FC<SvgIconProps> = ({
  sx,
  ...rest
}) => (
  <SvgIcon
    viewBox="0 0 76 76"
    sx={{ width: "76px", height: "76px", ...sx }}
    {...rest}
  >
    <path
      d="M57.6474 68.9135H10.9413C9.17324 68.9135 7.73047 67.4848 7.73047 65.7026V3.21088C7.73047 1.44279 9.15909
      1.52588e-05 10.9413 1.52588e-05H57.6616C59.4297 1.52588e-05 60.8725 1.42864 60.8725 3.21088V65.7167C60.8583
      67.4848 59.4156 68.9135 57.6474 68.9135Z"
      fill="#E6EFF6"
    />
    <path
      d="M54.621 59.6911H38.6374C37.9867 59.6911 37.4492 60.2286 37.4492 60.8793C37.4492 61.5299 37.9867 62.0674
      38.6374 62.0674H54.621C55.2716 62.0674 55.8092 61.5299 55.8092 60.8793C55.8092 60.2144 55.2858 59.6769 54.621 59.6911Z"
      fill="#005EA6"
    />
    <path
      d="M23.1911 40.1005C23.5447 40.6522 24.2802 40.8219 24.8318 40.4542C25.3835 40.1005 25.5391 39.365 25.1855
      38.8134C23.4457 36.1117 22.5404 32.9857 22.5404 29.7607C22.5404 20.4959 30.0796 12.9708 39.3303 12.9708C48.5951
      12.9708 56.1201 20.51 56.1201 29.7607C56.1201 32.9716 55.2007 36.1117 53.4751 38.8134C53.1214 39.365 53.277
      40.1005 53.8287 40.4542C54.0267 40.5815 54.253 40.638 54.4652 40.638C54.8612 40.638 55.2432 40.44 55.4695
      40.0864C57.4497 37.0028 58.4965 33.4242 58.4965 29.7607C58.4965 19.1945 49.8964 10.5945 39.3303 10.5945C28.7641
      10.5945 20.1641 19.1945 20.1641 29.7607C20.1641 33.4525 21.2108 37.017 23.1911 40.1005Z"
      fill="#005EA6"
    />
    <path
      d="M54.621 52.8591H38.6374C37.9867 52.8591 37.4492 53.3967 37.4492 54.0473C37.4492 54.698 37.9867 55.2355 38.6374
      55.2355H54.621C55.2716 55.2355 55.8092 54.698 55.8092 54.0473C55.8092 53.3967 55.2858 52.8591 54.621 52.8591Z"
      fill="#005EA6"
    />
    <path
      d="M41.5635 29.7759C41.5635 29.6345 41.5352 29.493 41.5211 29.3516L46.0332 26.749C46.599 26.4236 46.7971 25.6881
      46.4717 25.1223C46.1464 24.5565 45.4109 24.3585 44.8451 24.6838L40.3329 27.2865C39.7954 26.8763 39.1447 26.6217
      38.4233 26.6217C36.6835 26.6217 35.2832 28.0361 35.2832 29.7618C35.2832 31.4875 36.6977 32.9019 38.4233 32.9019C40.149
      32.9019 41.5635 31.5158 41.5635 29.7759ZM37.221 29.7759C37.221 29.1111 37.7585 28.5736 38.4233 28.5736C39.0881
      28.5736 39.6256 29.1111 39.6256 29.7759C39.6256 30.4408 39.0881 30.9783 38.4233 30.9783C37.7585 30.9783 37.221
      30.4408 37.221 29.7759Z"
      fill="#005EA6"
    />
    <path
      d="M32.2715 56.6499C31.1682 56.1548 29.98 56.07 28.9192 55.971C27.8583 55.8719 26.8965 55.7305 26.4014
      55.5042C26.0336 55.3344 25.7649 55.1223 25.7649 54.415C25.7649 52.9157 27.0662 51.7558 28.7494 51.7417C30.2629
      51.7982 31.2531 52.5338 31.7481 53.9907C32.2715 55.4052 34.5347 54.8111 34.1103 53.2976C33.5021 51.5012 32.2008
      50.0867 30.0225 49.5775V48.4601C30.0225 47.6963 29.4001 47.3143 28.7919 47.3143C28.1836 47.3143 27.5471 47.6821
      27.5471 48.4601V49.5634C25.1284 50.0726 23.2754 52.0246 23.2754 54.4292C23.2754 56.1266 24.1948 57.0884 25.2981
      57.5976C26.4014 58.0927 27.5896 58.1775 28.6504 58.2766C29.7113 58.3756 30.6731 58.517 31.1682 58.7433C31.4794
      58.8989 31.8047 59.0545 31.8047 59.8325C31.8047 61.3035 30.5034 62.52 28.8202 62.5341C27.4057 62.5059 26.2882
      61.6996 25.8215 60.2568C25.284 58.8141 23.0066 59.4789 23.4593 60.9499C24.2514 63.2414 25.9063 64.2881 27.533
      64.6842V65.6884C27.533 67.2444 30.0083 67.1878 30.0083 65.6884V64.6842C32.4412 64.1749 34.2801 62.1947 34.2801
      59.8183C34.2942 58.1351 33.3748 57.145 32.2715 56.6499Z"
      fill="#54C5B5"
    />
    <path
      d="M33.7283 17.7093C35.0862 17.0728 36.5714 16.6626 38.1415 16.5211V18.2327C38.1415 18.8833 38.679 19.4208 39.3296
      19.4208C39.9803 19.4208 40.5178 18.8833 40.5178 18.2327V16.5211C42.0879 16.6626 43.5589 17.0728 44.931
      17.7093L44.0823 19.1804C43.757 19.7461 43.955 20.4817 44.5208 20.807C44.7047 20.9202 44.9168 20.9626 45.1149
      20.9626C45.5251 20.9626 45.9211 20.7504 46.1474 20.3685L46.9961 18.8975C48.255 19.7744 49.3441 20.8777 50.2211
      22.1225L48.7501 22.9712C48.1843 23.2965 47.9862 24.032 48.3116 24.5978C48.5379 24.9797 48.934 25.1919 49.3442
      25.1919C49.5422 25.1919 49.7543 25.1353 49.9382 25.0363L51.4093 24.1876C52.0458 25.5455 52.456 27.0307 52.5975
      28.6008H50.8859C50.2353 28.6008 49.6978 29.1383 49.6978 29.789C49.6978 30.4396 50.2353 30.9771 50.8859
      30.9771H52.5975C52.456 32.5189 52.0458 34.0041 51.4093 35.3903L49.9241 34.5416C49.3583 34.2163 48.6228 34.4143
      48.2974 34.9801C47.9721 35.5459 48.1701 36.2814 48.7359 36.6067L50.2211 37.4554C49.9241 37.8798 49.5988 38.3041
      49.2451 38.686C48.8066 39.1811 48.8491 39.9308 49.33 40.3693C49.8251 40.8077 50.5747 40.7653 51.0132 40.2844C53.6017
      37.3988 55.0304 33.6788 55.0304 29.8031C55.0304 21.1465 47.9862 14.1024 39.3296 14.1024C30.673 14.1024 23.6289
      21.1465 23.6289 29.8031C23.6289 33.6788 25.0575 37.3988 27.646 40.2844C27.8865 40.539 28.2118 40.6804 28.5372
      40.6804C28.82 40.6804 29.1029 40.5814 29.3293 40.3834C29.8243 39.9449 29.8526 39.1952 29.4141 38.7002C29.0605
      38.3041 28.7493 37.8939 28.4381 37.4554L29.9233 36.6067C30.4891 36.2814 30.6872 35.5459 30.3618 34.9801C30.0365
      34.4143 29.3151 34.2163 28.7352 34.5416L27.25 35.3903C26.6135 34.0041 26.2033 32.5189 26.0618 30.9771H27.7875C28.4381
      30.9771 28.9756 30.4396 28.9756 29.789C28.9756 29.1383 28.4381 28.6008 27.7875 28.6008H26.076C26.2174 27.0307
      26.6276 25.5597 27.2641 24.1876L28.7352 25.0363C28.9191 25.1495 29.1312 25.1919 29.3293 25.1919C29.7395
      25.1919 30.1355 24.9797 30.3618 24.5978C30.6872 24.032 30.4891 23.2965 29.9233 22.9712L28.4523 22.1225C29.3293
      20.8636 30.4326 19.7744 31.6773 18.8975L32.526 20.3685C32.7523 20.7504 33.1484 20.9626 33.5586 20.9626C33.7566
      20.9626 33.9688 20.906 34.1526 20.807C34.7184 20.4817 34.9165 19.7461 34.5911 19.1804L33.7283 17.7093Z"
      fill="#005EA6"
    />
    <path
      d="M66.2909 2.33388H12.3709C11.2817 2.33388 10.3906 3.225 10.3906 4.31415V74.0197C10.3906 75.1089 11.2817
      76 12.3709 76H66.2909C67.38 76 68.2711 75.1089 68.2711 74.0197V4.31415C68.2711 3.225 67.38 2.33388 66.2909
      2.33388ZM65.8948 73.6236H12.7669V4.7102H65.8948V73.6236Z"
      fill="#B0CDE3"
    />
  </SvgIcon>
);
